import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
// import peep from "../assets/npc/g.svg";
import Home2 from "../assets/npc/home2.png";
import lpg from "../assets/npc/background.png";
import header from "../assets/npc/header.svg";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { Modal, Form, Container, Col, Row, Spinner } from "react-bootstrap";
import axios from "axios";
// import Dropdown from 'react-bootstrap/Dropdown';
import { handleChange } from "../redux/userSlice";
import { useDispatch } from "react-redux";
// import Swal from 'sweetalert2/dist/sweetalert2.js';
const Landing = () => {
  // const access = sessionStorage.getItem("access_code");
  // const { accessCode } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [accepted, setAccepted] = useState([
    "Karu",
    "Imeko Afon",
    "Teungo",
    "Brass",
    "Ogbaru",
    "Idemili South",
    "Toro",
  ]);
  const [nwShow, setNwShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [dqmMods, setDqmMods] = useState(false);
  const [lmShow, setLmShow] = useState(false);

  const [resumeShow, setResumeShow] = useState(false);
  // eslint-disable-next-line
  const [loc, setLoc] = useState("");
  const [dqmCode, setDqmCode] = useState("");
  const [ans, setAns] = useState("");
  const [dqmQuestion,setQuestion] = useState({});

  // eslint-disable-next-line
  const [rl, setRl] = useState(false);
  // eslint-disable-next-line
  const [loads, setLod] = useState(false);
  const [appNum, setAppNum] = useState("");
  const [codeNum, setCodeNum] = useState("");
  // const [lats2,setLats2] = useState('');
  // const [lons2,setLons2] = useState('');
  const [dataInf, setDataInfo] = useState(null);
  const [stat, setStat] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAcc, setShowAcc] = useState(false);
  // eslint-disable-next-line
  const [showSig, setShowSig] = useState(false);
  // eslint-disable-next-line
  const [showSigPrint, setShowSigPrint] = useState(false);
  const navigate = useNavigate();

  const handleAppCheck = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      var formdata = new FormData();
      formdata.append("access-code", appNum);

      var config = {
        method: "post",
        url: "https://npc-recruitment-api.verxid.site/api/retriveData",
        headers: {
          Authorization: "Basic YmFybmtzZm9ydGUtbmltYzowbmx5YmFybmtzMTIz",
        },
        data: formdata,
      };
      const { data } = await axios(config);
      // console.log(data.nimc);
      if (data.npc.length === 1) {
        setLoading(false);
        setDataInfo(data.npc[0]);
        if(data.npc[0].userstatus === 1 && data.npc[0].bank.account_number === ""){
          sessionStorage.setItem("access_code", data.npc[0].access_code);
          sessionStorage.setItem("last_name", data.npc[0].bio_data.lastname);
          sessionStorage.setItem("first_name", data.npc[0].bio_data.firstname);
        }
        else{
          sessionStorage.setItem("last_name", data.npc[0].bio_data.lastname);
          sessionStorage.setItem("first_name", data.npc[0].bio_data.firstname);
          
        }
        
        if (data.npc[0].bank.account_number === "") {
          setShowAcc(true);
        } else {
          setShowAcc(false);
        }

        if (data.npc[0].sign) {
          setShowSigPrint(true);
          setShowSig(false);
        } else {
          setShowSig(true);
        }
        if (data.npc[0].userstatus === 0) {
          setStat(" Pending");
        } else if (data.npc[0].userstatus === 1) {
          setStat("Approved");
        } else if (data.npc[0].userstatus === 2) {
          setStat("Declined");
        }
      } else {
        setLoading(false);
        toast.error("please retry access code", {
          position: "top-left",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("please retry", {
        position: "top-left",
      });
    }
  };


  const handleCodCheck = async (e) => {
    e.preventDefault();
    if(!codeNum){
      toast.error("please retry", {
        position: "top-left",
      });
      return;
    }
    else{
    setLoading(true);
    try {
      var formdata = new FormData();
      formdata.append("access-code", codeNum);

      var config = {
        method: "post",
        url: "https://npc-recruitment-api.verxid.site/api/retriveData",
        headers: {
          Authorization: "Basic YmFybmtzZm9ydGUtbmltYzowbmx5YmFybmtzMTIz",
        },
        data: formdata,
      };
      const { data } = await axios(config);
      console.log(data);
      if (data.npc[0].nin ==='') {
        setLoading(false);
      //   setDataInfo(data.npc[0]);
      //   sessionStorage.setItem("access_code", data.npc[0].access_code);
      //   sessionStorage.setItem("last_name", data.npc[0].bio_data.lastname);
      //   sessionStorage.setItem("first_name", data.npc[0].bio_data.firstname);
      dispatch(handleChange({name:'usertype',value:'adhoc'}))
      dispatch(handleChange({name:'dqmCode',value:data.npc[0].access_code}))
      sessionStorage.setItem('user-type','adhoc')
      sessionStorage.setItem('dqmCode',data.npc[0].access_code)
      setNwShow(false);
      setSmShow(true);
      //   if (data.npc[0].bank.account_number === "") {
      //     setShowAcc(true);
      //   } else {
      //     setShowAcc(false);
      //   }
      //   if (data.npc[0].sign) {
      //     setShowSigPrint(true);
      //     setShowSig(false);
      //   } else {
      //     setShowSig(true);
      //   }
      //   if (data.npc[0].userstatus === 0) {
      //     setStat(" Pending");
      //   } else if (data.npc[0].userstatus === 1) {
      //     setStat("Approved");
      //   } else if (data.npc[0].userstatus === 2) {
      //     setStat("Declined");
      //   }
      } else {
        setLoading(false);
        toast.error("please retry access code", {
          position: "top-left",
        });
        
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("please retry", {
        position: "top-left",
      });
    }
  }
  };
  const handleAppStart = async (e) => {
    e.preventDefault();
    navigate("/disclaimer");
  };


  // use this....
  // const newAd=()=>{
  //   dispatch(handleChange({name:'usertype',value:'adhoc'}))
  //   sessionStorage.setItem('user-type','adhoc')
  //   setSmShow(true);
  // }

//   const npcStaff=()=>{
//     dispatch(handleChange({name:'usertype',value:'npc'}))
//     sessionStorage.setItem('user-type','npc')
//     setSmShow(true);
//   }

//   const dqmStaff=()=>{
//     dispatch(handleChange({name:'usertype',value:'dqm'}))
//     sessionStorage.setItem('user-type','dqm');
// setDqmMods(true);

//   }

  const checkDqm=async (e)=>{
    e.preventDefault();
    setLoading(true);

    try {

      var config = {
        method: "get",
        url: "https://npc-recruitment-api.verxid.site/api/get-question",
        headers: {
          Authorization: "Basic YmFybmtzZm9ydGUtbmltYzowbmx5YmFybmtzMTIz",
        },
        // data: formdata,
      };
      const { data } = await axios(config);
      // console.log(data)
      setQuestion(data.npc[0]);
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      toast.error("please retry.", {
        position: "top-left",
      });
    }
  }

  const handleQuestionStart=async(e)=>{
    e.preventDefault();
    setLoading(true);
console.log(dqmCode)
    try {
      var formdata = new FormData();
      formdata.append("access-code", dqmCode);
      formdata.append("questionid", dqmQuestion?.questionid);
      formdata.append("answer", ans);
      // formdata.append("access-code", dqmCode);

      var config = {
        method: "post",
        url: "https://npc-recruitment-api.verxid.site/api/update-questionAnswered",
        headers: {
          Authorization: "Basic YmFybmtzZm9ydGUtbmltYzowbmx5YmFybmtzMTIz",
        },
        data: formdata,
      };
   
      const { data } = await axios(config);
      console.log(data)
      setLoading(false);
      if(data.npc.status ===1){
        toast.success("Answer submitted.", {
          position: "top-left",
        });
        dispatch(handleChange({name:'dqmCode',value:dqmCode}))
        sessionStorage.setItem('dqmCode',dqmCode);
         setDqmMods(false)
      setSmShow(true);
      }
      else{
          toast.error("please retry.", {
            position: "top-left",
          });
        }
      
    } catch (error) {
      setLoading(false);
      toast.error("please retry.", {
        position: "top-left",
      });
    }
  }

  const handleResume= async (e)=>{
    e.preventDefault();
    setLoading(true);
    try {
      var formdata = new FormData();
      formdata.append("access-code", appNum);
      var config = {
        method: "post",
        url: "https://npc-recruitment-api.verxid.site/api/retriveData",
        headers: {
          Authorization: "Basic YmFybmtzZm9ydGUtbmltYzowbmx5YmFybmtzMTIz",
        },
        data: formdata,
      };
      const { data } = await axios(config);
      if (data.npc.length === 1) {
        setLoading(false);             
        sessionStorage.setItem("nin", data.npc[0].nin);   
        sessionStorage.setItem("access_code", data.npc[0].access_code);
        dispatch(handleChange({name:'accessCode',value:data.npc[0].access_code}));
        if (data.npc[0].usertype === 'npc') {
          dispatch(handleChange({name:'usertype',value:'npc'}));
          sessionStorage.setItem('user-type','npc')
     } 
     else if (data.npc[0].usertype === 'adhoc') {
      dispatch(handleChange({name:'usertype',value:'adhoc'}));
      sessionStorage.setItem('user-type','adhoc');
 } 
 else if (data.npc[0].usertype === 'dqm') {
  dispatch(handleChange({name:'usertype',value:'dqm'}));
  sessionStorage.setItem('user-type','dqm');
} 
        if (data.npc[0].step === '1') {
          navigate("/residence");
        }else if (data.npc[0].step === '0') {
          navigate("/bio-data");
        } else if (data.npc[0].step === '2') {
          navigate("/contact");
        } else if (data.npc[0].step === '3') {
          navigate("/education-data");
        }
        else if (data.npc[0].step === '4') {
          navigate("/work");
        }
        else if (data.npc[0].step === '5') {
          navigate("/face-capture");
        }
        else if (data.npc[0].step === '6') {
          if(data.npc[0].usertype === 'npc'){
            toast.error("you have completed this application.", {
              position: "top-left",
            });
            return;
          }
          else{
            navigate("/gurantor");
          }
        }
        else if (data.npc[0].step === '7') {
          if(data.npc[0].usertype === 'adhoc'){
            toast.error("you have completed this application.", {
              position: "top-left",
            });
            return;
          }
        }
      } else {
        setLoading(false);
        toast.error("please retry access code", {
          position: "top-left",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("please retry", {
        position: "top-left",
      });
    }
  }



  //   useEffect(() => {
  //     navigator.geolocation.getCurrentPosition(function(position) {

  //       setLoc(`${position.coords.latitude},${position.coords.longitude}`);
  //       // setLons2(position.coords.longitude);
  //       // setLats2(position.coords.latitude);
  // const usez={
  //   lat:position.coords.latitude,
  //   long:position.coords.longitude
  // };
  // sessionStorage.setItem('userCord',JSON.stringify(usez));
  //     });

  //   },[]);

  //   useEffect(() => {

  //     const getAc = async ()=>{
  //       setLod(true);
  //       try {
  //         const rest = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${loc}&key=AIzaSyAxilHHgYI8vAoeAo9t1EDn2b6fF1YLUhI`)
  //         // console.log(rest.data.results[0].address_components);
  // const dz=rest.data.results[0].address_components.filter((it)=>it.types[0]==='administrative_area_level_2')

  // if(accepted.includes(dz[0].short_name)){

  // setRl(false);
  // setLod(false)
  // toast.success('Authorization Successful!Proceed with application', {
  //         position: "top-left",
  //       });
  // }
  // else{

  // setLod(false)
  // toast.success('Authorization Failed!Contact NPC.', {
  //   position: "top-left",
  // });
  // }
  //       } catch (error) {

  //         toast.error('Authorization failed!contact NPC', {
  //           position: "top-left",
  //         });
  //         setLod(false)
  //       }

  //     }
  //   if(loc ){

  //     getAc();
  //   }
  //   else{

  //     setRl(true);
  //   }
  // // eslint-disable-next-line
  //   }, [loc,accepted]);

  //   useEffect(() => {
  //     const getDistance=(lat1,lon1,lats2,lons2)=>{
  //       var lat2=Number(lats2);
  //       var lon2=Number(lons2);

  //       console.log(lat2,lon2)
  //       var p = 0.017453292519943295;    // Math.PI / 180
  //       var c = Math.cos;
  //       var a = 0.5 - c((lat2 - lat1) * p)/2 +
  //               c(lat1 * p) * c(lat2 * p) *
  //               (1 - c((lon2 - lon1) * p))/2;

  //       var dist = 12742 * Math.asin(Math.sqrt(a))*1000;
  //       console.log(dist)
  //       if(dist < 85){
  // setRl(false)
  //       }
  //       else{
  //         setRl(true)
  //       }
  //     }
  //     if(lats2&&lons2){
  //       getDistance(9.055464,7.456774,lats2,lons2);
  //     }
  //     else{
  //       // console.log('cant go');
  //       setRl(true);
  //     }

  //   }, [lats2,lons2])

  // console.log(lats2,lons2);

  // useEffect(() => {
  //   if(loc){

  //     setRl(false);
  //   }
  //       else{

  //         setRl(true); }

  // }, [loc])

  // console.log(Object.values(dqmQuestion?.options));
  return (
    <Wrapper>
      {loads && <Loader />}
      <span className="policy text-white"><a href={'https://barnksforte-statics.s3.eu-central-1.amazonaws.com/npc/E-RECRUITMENT_PRIVACY+POLICY.pdf'} rel="noreferrer" target='_blank'>Privacy Policy</a></span>
      <div className="sec-row">
        <div className="left-side">
          
           <img src={header} alt="peep" className="img mx-0" style={{width:'300px',height:'100px'}} />
          <h4 className=''>NPC AD-HOC STAFF RECRUITMENT 2023 APPLICATION</h4>
          <div className="dwn mx-0">
            <div className="cta-btns mx-0">

              {/* locked */}


            {/* <Dropdown className=" px-0 mx-0">
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      Start Application
      </Dropdown.Toggle>
      <Dropdown.Menu>
        
        <Dropdown.Item  onClick={() => setNwShow(true)}>Start with code</Dropdown.Item>
        <Dropdown.Item  onClick={() => setResumeShow(true)}>Resume application</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}


              {/* <button 
              // disabled={rl}
               className="my-btn small" onClick={() => setSmShow(true)}>
              Start Application
            </button> */}
              
              <button
                className="my-btn yellow-clr small py-2"
                // disabled={rl}
                onClick={() => setLmShow(true)}
              >
                Check Application Status
              </button>
            </div>
          </div>
        </div>

        <div className="right-side">
          <div className='img-cont'>
          <img src={Home2} alt="peep" className="img"  />
          </div>
        </div>
      </div>

      <Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton>
          <Container>
            <Row className="text-center">
              <Col sm={12} md={12}>
                <Modal.Title
                  id="example-modal-sizes-title-sm"
                  className="mx-auto"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;INSTRUCTIONS
                </Modal.Title>
              </Col>
              <Col sm={12} md={12}>
                <Modal.Body className="mx-auto">
                  {" "}
                  Applicants must have the following{" "}
                </Modal.Body>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            {" "}
            <span style={{ color: "red" }}>
              Click on each box to acknowledge instructions
            </span>
          </p>
          <Form className="" onSubmit={handleAppStart}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                required
                type="checkbox"
                label="Valid NIN"
                className="shadow_none form_check"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                required
                type="checkbox"
                label="Valid Phone Number (Remove number from DND.) "
                className="shadow_none form_check"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                required
                type="checkbox"
                label="Device location setting is on."
                className="shadow_none form_check"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                required
                type="checkbox"
                label="Valid Email Address"
                className="shadow_none form_check"
              />
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                required
                type="checkbox"
                label="Valid Bank Account Number"
                className="shadow_none form_check"
              />
            </Form.Group> */}
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                required
                type="checkbox"
                label="Valid Educational Certificate"
                className="shadow_none form_check"
              />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="my-btn"
                style={{ width: "200px" }}
              >
                PROCEED
              </button>
            </div>
          </Form>
          <div className="d-flex justify-content-center">
            <button
              className="my-btn btn-clear mt-2"
              style={{ width: "200px" }}
              onClick={() => setSmShow(false)}
            >
              CLOSE
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={lmShow}
        onHide={() => setLmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton>
          <Container>
            <Row className="text-center">
              <Col sm={12} md={12}>
                <Modal.Title
                  id="example-modal-sizes-title-sm"
                  className="mx-auto"
                > 
                  &nbsp;&nbsp;&nbsp;&nbsp;VIEW APPLICATION STATUS
                </Modal.Title>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Form className="" onSubmit={handleAppCheck}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Control
                required
                type="text"
                placeholder="Enter Your Application Code"
                className="form_input shadow-none"
                name="app_code"
                onChange={(e) => setAppNum(e.target.value)}
              />
            </Form.Group>

            <div className="d-flex justify-content-center">
              {!dataInf && (
                <button
                  type="submit"
                  className="my-btn"
                  onClick={handleAppCheck}
                >
                  PROCEED
                </button>
              )}
            </div>
          </Form>
          <button
            className="my-btn btn-clear mx-auto"
            onClick={() => {
              setLmShow(false);
              setAppNum("");
              setDataInfo(null);
              setStat("");
              sessionStorage.removeItem("access_code");
              sessionStorage.clear();
            }}
          >
            CLOSE
          </button>
          {dataInf && (
            <div>
              <p>
                Name:{" "}
                {`${dataInf.bio_data.lastname} ${dataInf.bio_data.firstname} ${dataInf.bio_data.middlename}`}
              </p>
              <p>
                Access-code:
                {`${dataInf.access_code}`}
              </p>
              <p>Application Status:{stat && stat}</p>
            </div>
          )}

          {stat === "Approved" &&
            // stat
            showAcc && (
              <div>
                <Link to="/banking-info">
                  <button className="btn my-btn">Submit bank details</button>
                </Link>
              </div>
            )}

          {/* {showSig && (
            <div>
              <Link to="/sign-agree">
                <button className="btn my-btn">Sign Agreement</button>
              </Link>
            </div>
          )}

          {showSigPrint && (
            <div>
              <Link to="/print">
                <button className="btn my-btn">Print Agreement</button>
              </Link>
            </div>
          )} */}
          {loading && (
            <div className="row py-3">
              <div className="col-6  col-md-6 mx-auto">
                <Spinner animation="border" variant="success" />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>




      <Modal
        size="md"
        show={nwShow}
        onHide={() => setNwShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton>
          <Container>
            <Row className="text-center">
              <Col sm={12} md={12}>
                <Modal.Title
                  id="example-modal-sizes-title-sm"
                  className="mx-auto"
                > 
                  &nbsp;&nbsp;&nbsp;&nbsp;APPLY WITH CODE
                </Modal.Title>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Form className="" onSubmit={handleCodCheck}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Control
                required
                type="text"
                placeholder="Enter Your  Code"
                className="form_input shadow-none"
                name="app_code"
                onChange={(e) => setCodeNum(e.target.value)}
              />
            </Form.Group>

            <div className="d-flex justify-content-center">
            
                <button
                  type="submit"
                  className="my-btn"
                  onClick={handleCodCheck}
                >
                  PROCEED
                </button>
            </div>
          </Form>
          <button
            className="my-btn btn-clear mx-auto"
            onClick={() => {
              setNwShow(false);
              setCodeNum("");
              sessionStorage.removeItem("access_code");
              sessionStorage.clear();
            }}
          >
            CLOSE
          </button>

          {/* {showSig && (
            <div>
              <Link to="/sign-agree">
                <button className="btn my-btn">Sign Agreement</button>
              </Link>
            </div>
          )}

          {showSigPrint && (
            <div>
              <Link to="/print">
                <button className="btn my-btn">Print Agreement</button>
              </Link>
            </div>
          )} */}
          {loading && (
            <div className="row py-3">
              <div className="col-6  col-md-6 mx-auto">
                <Spinner animation="border" variant="success" />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>


      <Modal
        size="md"
        show={resumeShow}
        onHide={() => setResumeShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton>
          <Container>
            <Row className="text-center">
              <Col sm={12} md={12}>
                <Modal.Title
                  id="example-modal-sizes-title-sm"
                  className="mx-auto"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;RESUME APPLICATION
                </Modal.Title>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Form className="" onSubmit={handleResume}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Control
                required
                type="text"
                placeholder="Enter Your Application Code"
                className="form_input shadow-none"
                name="app_code"
                onChange={(e) => setAppNum(e.target.value)}
              />
            </Form.Group>

           
                <button
                  type="submit"
                  className="my-btn"
            
                >
                  RESUME APPLICATION
                </button>
             
          </Form>
         
          {loading && (
            <div className="row py-3">
              <div className="col-6  col-md-6 mx-auto">
                <Spinner animation="border" variant="success" />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>


      <Modal
        size="lg"
        show={dqmMods}
        onHide={() => setDqmMods(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton>
          <Container>
            <Row className="text-center">
              <Col sm={12} md={12}>
                <Modal.Title
                  id="example-modal-sizes-title-sm"
                  className="mx-auto"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;DQM APPLICATION
                </Modal.Title>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Form className="" onSubmit={checkDqm}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Control
                required
                type="text"
                placeholder="Enter Your DQM Code"
                className="form_input shadow-none"
                name="dqm_code"
                onChange={(e) => setDqmCode(e.target.value)}
              />
            </Form.Group>

           
                <button
                  type="submit"
                  className="my-btn"
            
                >
                  NEXT
                </button>
             
          </Form>
         
          {loading && (
            <div className="row py-3">
              <div className="col-6  col-md-6 mx-auto">
                <Spinner animation="border" variant="success" />
              </div>
            </div>
          )}

          {
            dqmQuestion?.question && 
            <>
            <h4 className="mt-2 mb-0 text-center">Test Question</h4>
            <p className="text-center">
              {dqmQuestion?.question}
          </p>
          <Form className="">
            {
              dqmQuestion?.options &&
              Object.values(dqmQuestion?.options).map((item,i)=>{
                if(item===''){
return "";
                }
                return <Form.Group className="mb-3" controlId="formBasicCheckbox" key={i}>
                <Form.Check
                  // required
                  type="radio"
                  label={item}
                  className="shadow_none form_check"
                  value={`option${i +1}`}
                  name='ans'
                  onChange={(e)=>setAns(e.target.value)}
                />
              </Form.Group>
                
              })
            }
            
            </Form>
            <button
                  type="button"
                  className="my-btn"
            onClick={handleQuestionStart}
                >
                  SUBMIT ANSWER
                </button>
            </>
          }
        </Modal.Body>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  min-height: 100vh;
  width: 100%;
  background-image: url(${lpg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position:relative;

  .policy{
    position:absolute;
    bottom:5px;
    right:5px;
    font-size: 1rem;
    a{
    color:white;
    font-weight:bolder;
    }
  }
  .sec-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.6rem;
    padding: 2rem 2rem;
    .left-side {
      color: var(--white);
      padding-top: 14rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      .dwn {
        /* margin-top: 8rem; */

      }
      h1,
      h3 {
        margin-bottom: 1rem;
      }
      p {
        font-size: 1.7rem;
      }

      .cta-btns {
        display: flex;
        /* justify-content: center; */
        align-items: center;
        gap: 0.7rem;
      }
    }
    .right-side {
      position: relative;
     width: 100%;
      .img-cont{
        padding-top:7rem;
        /* position: absolute;
         top:0;
        left:30px; */
      
      }
    }
  }

  @media (max-width: 1000px) {
    .sec-row .left-side {
      padding-top: 3.4rem;
    }
    .sec-row .left-side .dwn {
      margin-top: 0rem;
    }
  }

  @media (max-width: 850px) {
   
    .sec-row {
      grid-template-columns: 1fr;
      text-align: center;
      padding-top: 21rem;
    }

    .sec-row .left-side .cta-btns {
      justify-content: center;
    }
    .sec-row .right-side {
      display: none;
    }
  }
  @media (max-width: 500px) {
    .sec-row {
     
      text-align: center;
      padding-top: 12rem;
    }
    .sec-row .left-side .cta-btns {
      flex-direction: column;
      gap: 0.6rem;
    }
  }
`;

export default Landing;
