import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",

  initialState: {
    accessCode: sessionStorage.getItem("access_code") || "",
    address: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.residence
    .address : "",
    registeredPerson:'',
    usertype:sessionStorage.getItem("user-type") || '',
    dqmCode:sessionStorage.getItem("dqmCode") || '',
    mapCenter: {
      lat: sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.residence.lat
        : 7.1,
      lng: sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.residence.lng
        : 9.0,
    },
    userState: "",
    lga: "",
    ward: "",
    ward2: "",
    userStateDet: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.state
    : "",
    lgaDet: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.lga
    : "",
    wardDet: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.residence
    .ward : "",
    userResStateDet: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.residence
    .state_resid : "",
    lgaResDet: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.residence
    .lga_resid : "",
    surname:
      sessionStorage.getItem("lname") ||
      JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.lastname ||
      "",
    firstname:
      sessionStorage.getItem("fname") ||
      JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.firstname ||
      "",
    midname: sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.middlename
      : "",
    gender:
      sessionStorage.getItem("gender") ||
      JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.gender ||
      "",
    date:
      sessionStorage.getItem("dob") ||
      JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.dob ||
      "",
    userResidenceState: "",
    lgaResidence: "",
    userResidenceState2: "",
    lgaResidence2: "",
    criminal: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.criminal_record
    : "", 
    computer: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.computer_skill
    : "", 
    language: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.language
    : "", 
    email: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.contact.email
    : "", 
    phone:
      sessionStorage.getItem("phone") ||
      JSON.parse(sessionStorage.getItem("dataMine"))?.contact.phone ||
      "",
    challenge: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.physical_challenge
    : "", 
    kinname: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.next_kin_name
    : "",
    kinrel: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.next_kin_relationship
    : "",
    kinphone: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.next_kin_phone
    : "",
    otherLang: sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.bio_data.other_language
      : "",
    jobDesc1: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.jobdesc1
    : "",
    jobDesc2: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.jobdesc2
    : "",
    jobDesc3: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.jobdesc3
    : "",
    jobDesc4: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.jobdesc4
    : "",
    jobDesc5: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.jobdesc5
    : "",
    workExperience: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.work_experince
    : "",
    otherExperience: "",
    projectName: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.other_exp_name_project
    : "",
      trialid:  sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.trial_id
      : "",
      smartphone: sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.smartphone
      : "",
    projectYear: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.other_exp_year_project
    : "",
    employed: "",
    orgName: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.empoly_name_company
    : "",
    empPosition: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.empoly_position
    : "",
    jobDesc6: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.jobdesc6
    : "",
    jobDesc7: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.jobdesc7
    : "",
    jobDesc8: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.jobdesc8
    : "",
      jobDesc9: sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.jobdesc9
      : "",
      jobDesc10: sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.jobdesc10
      : "",
      jobDesc11: sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.jobdesc11
      : "",
      jobDesc12: sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.jobdesc12
      : "",
      hqpf:sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.hqpf_number
      : "",
      ippis:sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.ippis
      : "",
       level:sessionStorage.getItem("dataMine")
       ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.level
       : "",
     department:sessionStorage.getItem("dataMine")
     ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.department
     : "",
      station:sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.dutystation
      : "",
      guarantorname:sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.guarantor.guarantor_name
      : "",
      guarantorstatus:sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.guarantor.guarantor_status
      : "",
      guarantorphone:sessionStorage.getItem("dataMine")
      ? JSON.parse(sessionStorage.getItem("dataMine"))?.guarantor.guarantor_number
      : "",

    poss: sessionStorage.getItem("dataMine")
    ? JSON.parse(sessionStorage.getItem("dataMine"))?.work_data.employ_desired
    : "",
    editBio: false,
    editRes: false,
    editCon: false,
    editEdu: false,
    editWork: false,
    editFace: false,
    editBank: false,
    editGuarantor: false,
    workExp:  "",
    otherExp:  "",
    emp:"",
  },

  reducers: {
    toggleHeader: (state) => {
      return { ...state, isOpen: !state.isOpen };
    },
    handleChange: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },

    handlewrkChange: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
    handleStChange: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
    handleMapChange: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
    handleCodChange: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
    clearValue: (state) => {
      const init = {
        address: "",
        mapCenter: {
          lat: 9.082,
          lng: 7.491302,
        },
        userState: "",
        lga: "",
        ward: "",
        surname: "",
        firstname: "",
        midname: "",
        gender: "",
        date: "",
        phone: "",
        criminal: "",
        computer: "",
        language: "",
        email: "",
        challenge: "",
        kinname: "",
        kinrel: "",
        kinphone: "",
        kinaddy: "",
        otherLang: "",
      };
      return {
        ...state,
        ...init,
      };
    },
  },
});

export const {
  toggleHeader,
  handleChange,
  clearValue,
  handleMapChange,
  handleCodChange,
  handleStChange,
  handlewrkChange,
} = userSlice.actions;

export default userSlice.reducer;
